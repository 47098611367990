import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";

import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Alert from "react-bootstrap/Alert";
import Image from "react-bootstrap/Image";

import { If, Then, Else, When, Unless } from "react-if";
import { RandomReveal } from "react-random-reveal";

import "./App.css";
import qFlipperScreenshot from "./qFlipper.png";

const DECIMAL = 10;
const A = "A";
const B = "B";
const failed = "Failed to bruteforce"
const hex_characters = "0123456789ABCDEF";

/*
const startRegex = /loclass-v1-info ts \d+ started/g;
const endRegex = /loclass-v1-info ts \d+ finished/g;
*/
const captureRegex =
  /loclass-v1-info ts \d+ started(?<logs>[\s\S]+?)loclass-v1-info ts \d+ finished/g;

function Attack() {
  const [keyA, setKeyA] = useState(null);
  const [keyB, setKeyB] = useState(null);
  const [error, setError] = useState(null);
  const [warning, setWarning] = useState(null);
  const [errorA, setErrorA] = useState(null);
  const [errorB, setErrorB] = useState(null);
  const [processingA, setProcessingA] = useState(false);
  const [processingB, setProcessingB] = useState(false);

  const parseLog = useCallback((log) => {
    const expectedLineCount = 2 + 2 * 9;
    var logSet = log;

    const matches = log.match(captureRegex);
    if (!matches || matches.length === 0) {
      setError(`No set of logs found`);
      return;
    }
    if (matches.length > 1) {
      const candidates = matches.filter((log) => {
        const lines = log.split("\n").filter((l) => l);
        return lines.length >= expectedLineCount;
      });
      if (candidates.length === 0) {
        setError(
          `Multiple sets of logs detected, but none are at least ${expectedLineCount} lines long`
        );
        return;
      } else {
        setWarning(
          `Multiple sets of logs detected.  Using most recent with correct length`
        );
        logSet = candidates[candidates.length - 1];
      }
    }

    const lines = logSet.split("\n").filter((l) => l);
    const lastIndex = lines.length - 1;

    if (logSet.match(captureRegex).length > 1) {
      setError(
        "Multiple sets of logs detected: remove all but one set of logs"
      );
      return;
    }

    // 9 lines per key, running twice for keyrollcheck, plus start and end lines
    if (lines.length < expectedLineCount) {
      setError(`Too many of too few lines: ${lines.length} should be 18`);
      return;
    }

    if (!lines[0].endsWith("started")) {
      setError("Incorrect start line");
      return;
    }

    if (!lines[lastIndex].endsWith("finished")) {
      setError("Incorrect end line");
      return;
    }

    let dataA = "";
    let dataB = "";

    // loclass-mac ts 1686142770 no 17 csn d25a82f8f7ff12e0 cc d2ffffffffffffff nr 00000000 mac 00000000
    // Slice to remove start and end lines, then slice to remove excess collected lines
    lines.slice(1, lastIndex).slice(0, 18).forEach((line) => {
      const parts = line.split(" ");
      if (!parts[0].startsWith("loclass-v1-mac")) {
        setError("Unexpected log format");
        return;
      }
      let logLine = "";
      logLine += parts[6];
      logLine += parts[8];
      logLine += parts[10];
      logLine += parts[12];

      const no = parseInt(parts[4], DECIMAL);
      if (no % 2 === 0) {
        dataA += logLine;
      } else {
        dataB += logLine;
      }
    });
    if (dataA.length > 0) {
      calculateKey(dataA, A);
    }
    if (dataB.length > 0) {
      calculateKey(dataB, B);
    }
  }, []);

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();

        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          // Do whatever you want with the file contents
          const binaryStr = reader.result;
          const decoder = new TextDecoder();
          const str = decoder.decode(binaryStr);
          try {
            parseLog(str);
          } catch (e) {
            // TODO: add error toast
            console.log(e);
          }
        };
        reader.readAsArrayBuffer(file);
      });
    },
    [parseLog]
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  async function calculateKey(data, which) {
    console.log("calculateKey", which, data);
    setError(null);
    if (which === A) {
      setKeyA(null);
      setErrorA(null);
      setProcessingA(true);
    } else if (which === B) {
      setKeyB(null);
      setErrorB(null);
      setProcessingB(true);
    }
    try {
      // TODO: try react-query (see defcon project)
      const response = await fetch("/lambda", {
        method: "POST",
        body: JSON.stringify({ log: data }),
      });
      if (response.ok) {
        const body = await response.json();
        const { key } = body;
        if (which === A) {
          setKeyA(key);
          setProcessingA(false);
        } else {
          setKeyB(key);
          setProcessingB(false);
        }
      } else {
        console.log("response not ok");
        const { msg, errorMessage } = await response.json();
        if (which === A) {
          setErrorA(msg || errorMessage);
          setProcessingA(false);
        } else if (which === B) {
          setErrorB(msg || errorMessage);
          setProcessingB(false);
        }
      }
    } catch (e) {
      console.log({ which }, e);
      if (which === A) {
        setProcessingA(false);
      } else if (which === B) {
        setProcessingB(false);
      }
    }
  }

  return (
    <Container className="text-center pt-5" fluid="md">
      <Row className="justify-content-center text-center">
        <Col xs="8">
          <Unless condition={processingA || processingB || keyA || keyB}>
            <p>
              Use <a href="https://flipperzero.one/update">qFlipper</a>'s File
              Manager to copy{" "}
              <code>sdcard/apps_data/picopass/.loclass.log</code> to your
              computer
            </p>

            <Image fluid src={qFlipperScreenshot} />
          </Unless>

          <When condition={error}>
            <Alert className="mt-1" variant="warning">
              {error}
            </Alert>
          </When>
          <When condition={warning}>
            <Alert className="mt-1" variant="info">
              {warning}
            </Alert>
          </When>

          <Unless condition={processingA || processingB}>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <Card className="pb-5">
                <Card.Body>
                  <Card.Title>Upload</Card.Title>
                  <Card.Text>
                    Drag 'n' drop log here, or click to select
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </Unless>
        </Col>
      </Row>
      <Row className="justify-content-center text-center">
        <Col xs="4">
          <When condition={errorA}>
            <Alert className="mt-1" variant="warning">
              Error with first key: {errorA}
            </Alert>
          </When>

          <When condition={processingA || keyA}>
            <Alert className="mt-5" variant="success">
              {keyA?.length > 0 ? "First key found: " : ""}
              <RandomReveal
                isPlaying
                characterSet={hex_characters}
                duration={keyA ? 1 : 30}
                characters={
                  processingA ? "FFFFFFFFFFFFFFFF" : keyA?.toUpperCase()
                }
              />
            </Alert>
          </When>
        </Col>

        <Col xs="4">
          <When condition={errorB}>
            <Alert className="mt-1" variant="warning">
              Error with second key: {errorB}
            </Alert>
          </When>

          <When condition={processingB || keyB}>
            <Alert className="mt-5" variant="success">
              {keyB?.length > 0 ? "Second key found: " : ""}
              <RandomReveal
                isPlaying
                characterSet={hex_characters}
                duration={keyB ? 1 : 30}
                characters={
                  processingB ? "FFFFFFFFFFFFFFFF" : keyB?.toUpperCase()
                }
              />
            </Alert>
          </When>
        </Col>
      </Row>
      <Row>
        <Col>
          <When
            condition={errorA?.includes(failed) || errorB?.includes(failed)}
          >
            <div className="text-left">
              <p>
                Reasons for loclass to fail:
              </p>
              <ul>
                <li>non-iClass picopass (Circuit Laundry, etc)</li>
                <li>iClass SE</li>
                <li>Readers configured with Standard-2 keyset</li>
                <li>Custom keyed readers using Standard KDF</li>
                <li>Custom keyed readers using SE KDF</li>
              </ul>
            </div>
          </When>
          <When condition={keyA && keyB}>
            <If condition={keyA !== keyB}>
              <Then>
                <p>Different keys: reader probably keyrolls</p>
                <p>
                  Place{" "}
                  <a
                    href={`data:text/plain;charset=utf-8,${encodeURIComponent(
                      keyA + "\n" + keyB + "\n"
                    )}`}
                    download="iclass_elite_dict_user.txt"
                  >
                    iclass_elite_dict_user.txt
                  </a>{" "}
                  into <code>sdcard/apps_data/picopass/assets/</code>
                </p>
              </Then>
              <Else>
                <p>Same key, reader not keyrolling</p>
                <p>
                  Place{" "}
                  <a
                    href={`data:text/plain;charset=utf-8,${encodeURIComponent(
                      keyA + "\n"
                    )}`}
                    download="iclass_elite_dict_user.txt"
                  >
                    iclass_elite_dict_user.txt
                  </a>{" "}
                  into <code>sdcard/apps_data/picopass/assets/</code>
                </p>
              </Else>
            </If>
          </When>
        </Col>
      </Row>
    </Container>
  );
}

export default Attack;
