import React, { useState, useEffect } from "react";

import { When } from 'react-if';

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";

function KeyDiversification() {
  const [key, setKey] = useState(null);
  const [csn, setCSN] = useState(null);
  const [result, setResult] = useState("");

  useEffect(() => {
    async function fetchData(key, csn) {
      try {
        // TODO: try react-query (see defcon project)
        const response = await fetch("/.netlify/functions/key_diversification", {
          method: "POST",
          body: JSON.stringify({ key, csn }),
        });
        if (response.ok) {
          const body = await response.json();
          const { key } = body;
          setResult(key.toUpperCase());
        } else {
          console.log("response not ok");
          const { msg } = await response.json();
          setResult(msg);
        }
      } catch (e) {
        console.log(e);
        setResult("error: " + e);
      }
    }

    if (key && csn) {
      const cleanKey = key.split(" ").join("")
      const cleanCSN = csn.split(" ").join("")
      if (cleanKey.length === 16 && cleanCSN.length === 16) {
        fetchData(cleanKey, cleanCSN);
      }
    }
  }, [key, csn])

  return (
    <Container className="text-center pt-5" fluid="md">
      <Row className="justify-content-center text-center">
        <Col xs="3">
          <p>System Elite Key (hexadecimal, spaces will be removed)</p>
          <Form.Control type="text" name="key" onChange={(event) => {setKey(event.target.value)}} />

        </Col>
        <Col xs="3">
          <p>CSN (Card Serial Number)</p>
          <Form.Control type="text" name="csn" onChange={(event) => {setCSN(event.target.value)}} />
        </Col>
        <Col xs="3">
          <When condition={result.length > 0}>
            <Alert className="mt-5" variant="success">{result}</Alert>
          </When>
        </Col>
      </Row>
    </Container>
  );
}

export default KeyDiversification;
