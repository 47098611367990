import React from "react";
import { Route, Routes, NavLink } from "react-router-dom";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Attack from "./Attack";
import KeyDiversification from "./KeyDiversification";

import "./App.css";

function App() {
  return (
    <div className="App">
      <Navbar collapseOnSelect expand="sm" bg="dark" variant="dark">
        <Navbar.Brand as={NavLink} to="/">
          <Navbar.Text>Loclass calculation</Navbar.Text>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link as={NavLink} to="/key_diversification">Key Diversification</Nav.Link>
          </Nav>
          <Nav>
            <Navbar.Text>
              For use with{" "}
              <a href="https://lab.flipper.net/apps/picopass">Picopass</a> app
              for Flipper Zero.
            </Navbar.Text>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <Routes>
        <Route index path="/" element={<Attack />} />
        <Route index path="/key_diversification" element={<KeyDiversification />} />
      </Routes>

      <footer className="footer font-small mt-5">
        <Container fluid className="text-center">
          <Row noGutters>
            <Col>
              <small className="text-muted">
                <a href="https://www.buymeacoffee.com/bettse/">Buy me a coffee</a>
              </small>
            </Col>
            <Col></Col>
            <Col>
              <small className="text-muted"></small>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
}

export default App;
